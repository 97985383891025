import React, { FC, CSSProperties } from 'react'
import { BiCog } from "react-icons/bi"




type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const GearIcon: FC<Props> = ( { onClick, className, style } ) =>
    <BiCog
        className={ className } style={ style } onClick={ () => onClick && onClick() } />
