import { useState, useEffect, useRef, TouchEvent } from "react"

type props = {
    container: HTMLElement | Document | null,
    goBack: () => void,
    refresh: () => void,
    goingBack: boolean,
    refreshing: boolean
}

const isDocument = ( element: any ): element is Document => element === document

function useInfiniteScroll ( {
    container,
    goBack,
    refresh,
    goingBack,
    refreshing
}: props ) {
    const [ startScrollY, setStartScrollY ] = useState( 0 )
    const [ startPullPositionY, setStartPullPositionY ] = useState( 0 )
    const [ touchToScroll, setTouchToScroll ] = useState( false )

    const startScrollYRef = useRef( startScrollY )
    const goingBackRef = useRef( goingBack )
    const refreshingRef = useRef( refreshing )
    const touchToScrollRef = useRef( touchToScroll )

    const onWheel = () => onScroll()

    const onScroll = () => {
        if ( !container ) return
        let containerHeight
        let contentScrollY
        let contentHeight
        if ( isDocument( container ) ) {
            containerHeight = document.documentElement.offsetHeight
            contentScrollY = document.documentElement.scrollTop || document.body.scrollTop;
            contentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        }
        else {
            containerHeight = container.offsetHeight // 固定値 入れ物の大きさ
            contentScrollY = container.scrollTop; // 入れ物の中身のスクロール 0がtop 
            contentHeight = container.scrollHeight // 入れ物の中身の大きさ
        }


        const scrollDirection = startScrollYRef.current <= contentScrollY ? "down" : "up"
        setStartScrollY( contentScrollY )
        if ( goingBackRef.current || refreshingRef.current ) {
            return
        }
        if ( containerHeight + contentScrollY + 8 > contentHeight && scrollDirection === "down" ) {

            goBack()
        }
        if ( !window.ontouchstart && contentScrollY === 0 && scrollDirection === "up" ) {
            if ( touchToScrollRef.current ) {
                return
            }
            refresh()
        }
    }

    useEffect( () => {
        container && container.addEventListener( "scroll", onWheel )
        container && container.addEventListener( "wheel", onWheel )
        return () => {
            container && container.removeEventListener( "scroll", onWheel )
            container && container.removeEventListener( "wheel", onWheel )
        }
    }, [ container ] )

    useEffect( () => {
        startScrollYRef.current = startScrollY
    }, [ startScrollY ] )

    useEffect( () => {
        touchToScrollRef.current = touchToScroll
    }, [ touchToScroll ] )

    useEffect( () => {
        goingBackRef.current = goingBack
    }, [ goingBack ] )

    useEffect( () => {
        refreshingRef.current = refreshing
    }, [ refreshing ] )



    const onStart = ( e: TouchEvent ) => {
        setTouchToScroll( true )
        setStartPullPositionY( e.touches[ 0 ].clientY )
    }

    const onEnd = ( e: TouchEvent ) => {
        if ( refreshingRef.current ) { return }
        let scrollY = isDocument( container ) ?
            document.documentElement.scrollTop || document.body.scrollTop :
            ( container ? container.scrollTop : 0 )
        if ( scrollY <= 0 && ( e.changedTouches[ 0 ].clientY - startPullPositionY ) > 100 ) {
            refresh()
        }
    }

    return {
        onTouchStart: onStart,
        onTouchEnd: onEnd,
    };
}

export default useInfiniteScroll
