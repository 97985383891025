import React, { useState, useMemo, FC } from "react"
import Styles from "../styles/UserProfileImage.module.scss";
import Modal from "../components/Modal"
import getNewPathAvoidingCache from "../functions/getNewPathAvoidingCache"
import { UserIcon } from "../icons/UserIcon";

type Props = {
    smallImageURL?:string,
    largeImageURL?:string
}

const UserProfileImage: FC<Props> = ( {
    smallImageURL,
    largeImageURL
} ) => {

    const [ profileImageModalVisible, setProfileImageModalVisible ] = useState( false )

    return useMemo( () =>
        <div>
            { smallImageURL ?
                <img
                    onClick={ () => largeImageURL && setProfileImageModalVisible( true ) }
                    src={ smallImageURL || undefined }
                    onError={ ( e ) => getNewPathAvoidingCache( e.target as HTMLImageElement ) }
                    className={ Styles.user_small_icon } />
                :
                <div className={ Styles.user_small_icon }>
                    <UserIcon className={ Styles.user_icon } />
                </div> }
            <Modal
                visible={ profileImageModalVisible }
                closeModal={ () => { } }
                closeModalIconVisible={ false }>
                <div
                    onClick={ () => setProfileImageModalVisible( false ) }
                    className={ Styles.image_container }>
                    <img
                        src={ largeImageURL }
                        onError={ ( e ) => getNewPathAvoidingCache( e.target as HTMLImageElement ) }
                        className={ Styles.user_large_icon }
                    />
                </div>
            </Modal>
        </div>, [ profileImageModalVisible ] )
}

export default UserProfileImage
