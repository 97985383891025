import React, { FC, CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUsers } from '@fortawesome/free-solid-svg-icons'


type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const UsersIcon: FC<Props> = ( { onClick, className, style } ) =>
    <FontAwesomeIcon
        icon={ faUsers as IconProp }
        className={ className } style={ style } onClick={ () => onClick && onClick() } />
