import React, { useState, useEffect, useRef, FC } from "react"
import { Link, navigate } from "gatsby"
import Styles from "../styles/mypage.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PostItem from "../components/postItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faUser, faSignOutAlt, faInfoCircle, faCog } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import UserProfileImage from "../components/UserProfileImage";
import { connect } from "react-redux";
import { resetStore, updateUserPostList, updateMyPageNextPostURL, AppState } from "../state/app";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage";
import { PostTimeLineType } from "../types/models/Post";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import client from "../apis/client";
import { GearIcon } from "../icons/GearIcon";
import { UsersIcon } from "../icons/UsersIcon";
import { TableHeaderIcon } from "../icons/TableHeaderIcon";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";

type Props = {
    resetStore: () => void,
    userPostList: PostTimeLineType[],
    updateUserPostList: ( postList: PostTimeLineType[] ) => void,
    updateMyPageNextPostURL: ( URL: string ) => void,
    myPageNextPostURL: string
}

const MyPage: FC<Props> = ( {
    resetStore,
    userPostList,
    updateUserPostList,
    updateMyPageNextPostURL,
    myPageNextPostURL
} ) => {

    const menuList = [
        { name: "チーム", icon: faUsers, func: () => navigate( "/team_info" ) },
        { name: "登録情報", icon: faUser, func: () => navigate( "/user_update" ) },
        { name: "ログアウト", icon: faSignOutAlt, func: () => logout() },
        { name: "設定", icon: faCog, func: () => navigate( "/settings" ) },
        { name: "ヘルプ", icon: faInfoCircle, func: () => navigate( "/help" ) },
        { name: "", icon: "", func: () => { } },
        { name: "", icon: "", func: () => { } },
        { name: "運営からのお知らせ", func: () => navigate( "/news_list" ), icon: faEnvelope, },
        { name: "利用規約", icon: faFileAlt, func: () => navigate( "/terms" ) },
        { name: "プライバシーポリシー", icon: faUser, func: () => navigate( "/privacy_policy" ) },
    ]

    const logout = () => {
        if ( window.confirm( '本当にログアウトしますか？' ) ) {
            client.post( "logout", {}, { withCredentials: true } )
                .then( res => {
                    localStorage.clear()
                    sessionStorage.clear()
                    resetStore()
                    navigate( "/" )
                } )
                .catch( e => {
                    console.log( e )
                    alert( "ログアウトに失敗しました" )
                } )
        }
        return
    }

    const [ userData, setUserData ] = useState( getInitialStateFromSessionStorage( "userData", null ) )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ drawerOpen, setDrawerOpen ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )
    const isLoggedIn = useIsLoggedIn()

    const myPageNextPostURLRef = useRef( myPageNextPostURL )
    const userPostListRef = useRef( userPostList )

    useEffect( () => {
        myPageNextPostURLRef.current = myPageNextPostURL
    }, [ myPageNextPostURL ] )

    useEffect( () => {
        userPostListRef.current = userPostList
    }, [ userPostList ] )

    const headerOption = {
        headerTitle: "マイページ",
        rightIcon:
            drawerOpen ?
                <GearIcon onClick={ () => setDrawerOpen( false ) } style={ { fontSize: 22 } } /> :
                <GearIcon onClick={ () => setDrawerOpen( true ) } style={ { fontSize: 22 } } />
    }

    const getUserData = async () => {
        const userID = localStorage.getItem( "userID" )
        const response_user = await client.get( `/api/users/me/`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } );
        const user_data = await response_user.data;
        const user_data_object = {
            name: user_data.last_name,
            team: user_data.community,
            statusMessage: user_data.status_message,
            profile_image_100: user_data.profile_image_100,
            profile_image_400: user_data.profile_image_400
        }
        sessionStorage.setItem( "userData", JSON.stringify( user_data_object ) )
        setUserData( user_data_object )

        const response_post = await client.get( `/api/users/me/posts/`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        } );
        const post_data = await response_post.data;
        if ( userPostList.length === 0 ) {
            updateUserPostList( post_data.results )
            updateMyPageNextPostURL( post_data.next )
            setFetchAtLeastOnce( true )
        }
    }

    useEffect( () => {
        if ( !isLoggedIn ) return
        getUserData()
        const scroll = sessionStorage.getItem( "mypageScroll" )
        document.documentElement.scrollTop = scroll ? JSON.parse( scroll ) : 0
        return () => sessionStorage.setItem( "mypageScroll", JSON.stringify( document.documentElement.scrollTop ) )
    }, [ isLoggedIn ] )



    const goBack = async () => {
        if ( !myPageNextPostURLRef.current ) return
        setGoingBack( true )
        await client.get( myPageNextPostURLRef.current, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "X-Requested-With": "XMLHttpRequest",
            },
        } ).then( response => {
            const additional_post = response.data.results
            const newUserPostList = userPostListRef.current.concat( additional_post )
            updateUserPostList( newUserPostList.slice() )
            updateMyPageNextPostURL( response.data.next )
        } ).catch( error => {
            console.log( { error } )
        } );

        setTimeout( () => setGoingBack( false ), 500 )

    }

    const refresh = async () => {
        const userID = localStorage.getItem( "userID" )
        const params = { pk_list: userPostListRef.current.map( post => post.pk ) }
        setRefreshing( true )
        await client.get( `/api/users/me/posts/latest/`, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: params
        } )
            .then( response => {
                const additional_post = response.data.result
                let newUserPostList = additional_post.concat( userPostListRef.current )
                updateUserPostList( newUserPostList )
            } )
            .catch( error => console.warn( error.response ) );
        setTimeout( () => setRefreshing( false ), 500 )
    }

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document !== "undefined" ? document : null,
        goBack,
        refresh,
        goingBack,
        refreshing
    } )

    const renderUserData = () =>
        <div className={ Styles.profile_container }>
            <UserProfileImage
                smallImageURL={ userData.profile_image_100 }
                largeImageURL={ userData.profile_image_400 } />
            <div className={ Styles.profile_text_container }>
                <p className={ Styles.name_container }>
                    { userData.name }
                </p>
                { userData.team &&
                    <div className={ Styles.team_container }>
                        <UsersIcon style={ { marginRight: 8 } } />
                        <Link to="/team_profile/" state={ { teamID: userData.team.pk, teamName: userData.team.name } }>
                            <span>{ userData.team.name }</span>
                        </Link>
                    </div> }
                <p className={ Styles.status_message }>
                    { userData.statusMessage }
                </p>
            </div>
        </div>

    const renderEmpty = () =>
        <div style={ { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", marginBottom: 16 } } >
            <TableHeaderIcon style={ { margin: "16px 0", fontSize: 72, color: "rgba(0,0,0,0.3)" } } />
            <p style={ { color: "rgba(0,0,0,0.54)", marginBottom: 8 } }>自分の投稿を表示します</p>
            <p style={ { color: "rgba(0,0,0,0.54)" } }>下に引っ張るか、リロードすると更新できます</p>
        </div>

    return (
        <Layout headerOption={ headerOption }>
            <>
                <div
                    style={ drawerOpen ? { transform: "translate3d(0,0,0)" } : {} }
                    className={ Styles.menu_container } onClick={ () => setDrawerOpen( false ) }>
                    <ul style={ drawerOpen ? { transform: "translate3d(0,0,0)" } : {} }>
                        { menuList.map( ( menu, index ) =>
                        ( <li onClick={ () => menu.func() } key={ index }>
                            { menu.icon &&
                                <FontAwesomeIcon
                                    icon={ menu.icon as IconProp }
                                    style={ { margin: "0 12px", fontSize: "0.8rem", width: 20 } }
                                    color={ "rgb(101, 119, 134)" } /> }
                            { menu.name }
                        </li> )
                        ) }
                    </ul>
                </div>
                <SEO title="Mypage" />
                { refreshing && <ActivityIndicator /> }
                { !refreshing &&
                    <div className={ Styles.hidden_activity_indicator }>
                        <ActivityIndicator />
                    </div> }
                <div className={ Styles.container }
                    { ...infiniteScroll }>
                    <div className={ Styles.info_container_wrapper }>
                        <div className={ Styles.info_container }>
                            <div>
                                { userData && <UserProfileImage
                                    smallImageURL={ userData.profile_image_100 }
                                    largeImageURL={ userData.profile_image_400 } /> }
                            </div>
                            <div className={ Styles.info_wrapper }>
                                <div className={ Styles.info_number_container }>
                                    <div className={ Styles.info_unit }>
                                        <span>107</span>
                                        <span>投稿</span>
                                    </div>
                                    <div className={ Styles.info_unit }>
                                        <span>520</span>
                                        <span>フォロワー</span>
                                    </div>
                                    <div className={ Styles.info_unit }>
                                        <span>438</span>
                                        <span>フォロー</span>
                                    </div>
                                </div>
                                <div className={ Styles.edit_profile }>
                                    <span>プロフィールを編集</span>
                                </div>
                            </div>
                        </div>
                        <div className={ Styles.under_construction }>
                            <p>現在実装中の機能です</p>
                        </div>
                    </div>
                    { userData && renderUserData() }
                    <div className={ Styles.user_post_container }>
                        { userPostList.length === 0 && fetchAtLeastOnce && renderEmpty() }
                        { userPostList.map( ( post: any ) => <PostItem key={ post.pk } post={ post } userIconVisible={ false } />
                        ) }
                        { goingBack && <ActivityIndicator /> }
                    </div>
                </div>
            </>
        </Layout>
    );
}

const mapStateToProps = ( state: AppState ) => ( {
    userPostList: state.app.userPostList,
    myPageNextPostURL: state.app.myPageNextPostURL
} )

const mapDispatchToProps = {
    resetStore,
    updateUserPostList,
    updateMyPageNextPostURL
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( MyPage )


